import {
  IResourceComponentsProps,
  useNavigation,
  useRouterContext,
} from "@pankod/refine-core";
import { Form, SaveButton, useForm } from "@pankod/refine-antd";
import { FormList } from "../stores/form";
import { authProvider } from "authProvider";
import { useLocation } from "@pankod/refine-react-router-v6";

export const SignUp: React.FC<IResourceComponentsProps> = () => {
  const { Link } = useRouterContext();
  const { formProps } = useForm<any>({});
  const { list } = useNavigation();
  const location = useLocation();

  const onSuccess = async (values: any) => {
    await (authProvider as any).register(values);

    // The code below chooses how to redirect the user after registration.
    // If a ?redirect query param is in the URL, redirect to that location,
    // or else go to the login page.
    const searchParams = new URLSearchParams(location.search);
    const redirect = searchParams.get("redirect");

    if (typeof redirect === "string") {
      window.location.href = redirect;
    } else {
      // Below line will redirect to the login page instead actually
      // because the orders list page is only available for logged in stores,
      // and after registration, no store will be automatically logged in.
      list("orders");
    }
  };

  const formView = () => (
    <Form
      {...formProps}
      onFinish={(values) => onSuccess(values)}
      layout="vertical"
      initialValues={{ phoneCode: "+44" }}
    >
      <FormList formProps={formProps} type="create" />
      <Form.Item style={{ textAlign: "right" }}>
        <Link to="/login" style={{ marginRight: 10 }}>
          Login
        </Link>
        <SaveButton htmlType="submit" type="primary">
          Sign Up
        </SaveButton>
      </Form.Item>
    </Form>
  );

  return (
    <div
      style={{
        width: "90%",
        textAlign: "center",
        margin: "0 auto",
      }}
    >
      <Link to="/">
        <img src="/images/logo.png" alt="Logo" width="200" />
      </Link>
      <div
        style={{
          padding: 20,
          textAlign: "left",
          border: "solid 1px #ccc",
          borderRadius: 10,
          margin: "0 auto",
        }}
      >
        {formView()}
      </div>
    </div>
  );
};
