import React, { useEffect, useRef, useState } from "react";
import { useShow, useOne, IResourceComponentsProps } from "@pankod/refine-core";
import moment from "moment";
import {
  List,
  ExportButton,
  Space,
  notification,
  Timeline,
  Button,
  Descriptions,
} from "@pankod/refine-antd";
import { OrderStatus, Loader, Invoice } from "components";
import { IOrder } from "interfaces";
import common from "common";
import GeneratePdf from "./GeneratePdf";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../firebase";
import QRCode from "qrcode.react";
import { getDateWithoutArray } from "./list";

const getBags = (order: any) => {
  if (order.bags) {
    if (typeof order.bags === "number") {
      return order.bags;
    } else if (Array.isArray(order.bags)) {
      return order.bags.length;
    }
  } else if (order.bagCodes) {
    return order.bagCodes.length;
  } else {
    return 1;
  }
};

const getLabel = (label: string, idx: number) => {
  if (idx === 0) {
    const date = getDateWithoutArray(label);
    const isDateValid = !isNaN(Number(date));
    if (isDateValid) {
      return date;
    } else {
      return label;
    }
  } else {
    return label;
  }
};

const getDateArray = (order: any) => {
  if (order && order.orderStatusArray) {
    return order.orderStatusArray.map((item: any, idx: number) => {
      const label = getLabel(item.label, idx);
      return {
        ...item,
        label: moment(label).format("MMMM Do YYYY, h:mm:ss a"),
      };
    });
  } else {
    return [];
  }
};

export const OrderShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow<IOrder>();
  const { data, isLoading } = queryResult;
  const order: any = data?.data ? data?.data : {};
  console.log(order);
  const componentRef = useRef<any>(null);
  const [openPdf, setOpenPdf] = useState(false);
  const [openGenerate, setOpenGenerate] = useState(false);
  const [customer, setCustomer] = useState<any>(null);
  const [store, setStore] = useState<any>(null);
  const [qrImage, setQrImage] = useState<any>(null);

  useEffect(() => {
    const loadCustomer = async () => {
      if (order && order.user && order.user !== "") {
        const userID = order.user;
        const docRef = doc(db, "users", userID);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data: any = docSnap.data();
          setCustomer(data);
        }
      }
    };
    const loadStore = async () => {
      if (order && order.store) {
        const storeID = order.store;
        const docRef = doc(db, "stores", storeID);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          console.log("loadStore", data);
          setStore(data);
        } else {
          const q = query(
            collection(db, "stores"),
            where("uid", "==", storeID)
          );
          const querySnapshot = await getDocs(q);
          if (querySnapshot.docs.length > 0) {
            const data = querySnapshot.docs[0].data();
            setStore(data);
          }
        }
      }
    };
    loadCustomer();
    loadStore();
  }, [order]);

  const dateArray = getDateArray(order);

  const storeDetails = useOne<any>({
    resource: "stores",
    id: order?.store,
  });

  const exportData = () => {
    if (!order?.user) {
      notification.error({
        message: "Error",
        description: "User not assigned",
      });
    } else {
      setOpenPdf(true);
    }
  };

  if (isLoading || store === null || customer === null) return <Loader />;

  const qrString = `{ "storeID": "${order.store}", "bagID": "${order.bagCodes[0]}" }`;

  const showDeliveryPdf = () => {
    const canvas = document.getElementById("qr") as HTMLCanvasElement;
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    setQrImage(pngUrl);
    setOpenGenerate(true);
  };

  return (
    <List
      title={"Order Details"}
      headerButtons={
        <Space>
          <Button onClick={() => history.back()}>Back</Button>
          <ExportButton onClick={() => exportData()} />
          {store && store.canPrintDelivery && (
            <Button onClick={() => showDeliveryPdf()}>Delivery label</Button>
          )}
        </Space>
      }
    >
      <GeneratePdf
        order={order}
        customer={customer}
        storeName={store.title}
        open={openGenerate}
        close={() => setOpenGenerate(false)}
        qr={qrImage}
      />
      {openPdf && <Invoice order={order} setOpenPdf={setOpenPdf} />}
      <div id="exportSpace" ref={componentRef}>
        <Descriptions title="Order Info" bordered column={2}>
          <Descriptions.Item label="Order Number">
            {order.orderNumber}
          </Descriptions.Item>
          <Descriptions.Item label="Service Type">
            {order.serviceType}
          </Descriptions.Item>
          <Descriptions.Item label="Bags">{getBags(order)}</Descriptions.Item>
          <Descriptions.Item label="Paid">
            {order.isPaid ? "Done" : "Not Done"}
          </Descriptions.Item>
          <Descriptions.Item label="Logistic Company Provider">
            {order.logisticCompanyProvider}
          </Descriptions.Item>
          <Descriptions.Item label="Logistic Confirmation Number">
            {order.logisticConfirmationNumber}
          </Descriptions.Item>
          <Descriptions.Item label="Payment Confirmation">
            {order.paymentConfirmation}
          </Descriptions.Item>
          <Descriptions.Item label="Service Price">
            {order.currencySymbol || common.currency}
            {order.total}
          </Descriptions.Item>
          <Descriptions.Item label="Order Status">
            <OrderStatus status={order.orderStatus} />
          </Descriptions.Item>
        </Descriptions>
        <Descriptions title="Order Status Info" bordered>
          <Descriptions.Item>
            <Timeline mode={"left"} items={dateArray} />
          </Descriptions.Item>
        </Descriptions>
        <Descriptions title="QR Code">
          <Descriptions.Item>
            <QRCode
              id="qr"
              value={qrString}
              size={290}
              level={"M"}
              mode="canvas"
              includeMargin={true}
            />
          </Descriptions.Item>
        </Descriptions>
      </div>
    </List>
  );
};
