import {
  doc,
  collection,
  getDocs,
  getDoc,
  addDoc,
  updateDoc,
  deleteDoc,
  query,
  where,
  orderBy,
} from "firebase/firestore";
import { db } from "./firebase";
import { v4 as uuidv4 } from "uuid";
import md5 from "md5";
import { authProvider } from "authProvider";
import { getDate } from "pages/orders";

function generateNumericUUID(): number {
  let id = "";

  // There may be some edge cases where the hash does not have 13 digits
  // as we would like.
  // So loop until we build a string that has 13 digits,
  // generating a new hash each time.
  while (id.length !== 13) {
    let uuid = uuidv4();
    let hash = md5(uuid);
    for (const chr of hash) {
      if (!isNaN(parseInt(chr))) {
        if (id.length === 13) {
          break;
        }
        id = id + chr;
      }
    }
  }

  return parseInt(id.substring(0, 13));
}

export const dataProvider: any = {
  create: async ({ resource, variables, metaData }: any) => {
    let params = variables;
    if (resource === "register") {
      await (authProvider as any).register(params);
      history.back();
      return;
    }
    if (resource === "orders") {
      params.orderNumber = generateNumericUUID();
      params.isPaid = false;
      params.orderStatus = "Order Received";
      params.orderStatusArray = [
        { children: "Order Received", label: String(new Date()) },
      ];
      params.user = params.user ? params.user : "";
      params.employeeID = params.employeeID ? params.employeeID : "";
    }
    params["createAt"] = String(new Date());
    params["updateAt"] = String(new Date());
    const dbRef = collection(db, resource);
    let q = query(collection(db, resource));
    const querySnapshot = await getDocs(q);
    const recordCount = querySnapshot.size;
    return addDoc(dbRef, params)
      .then((docRef) => {
        // Returnning docRef is needed for one piece of functionality so far.
        // After creating an order, we return the docRef from the data provider to the caller,
        // and the caller saves it in local storage, redirecting to the page orders list page after.
        // The order list page uses the docRef to pull the order details and display a modal QR code,
        // removing the cookie from local storage after the modal is closed.
        return Promise.resolve({ docRef });
      })
      .catch((error) => {
        history.back();
        return Promise.reject(error);
      });
  },
  createMany: ({ resource, variables, metaData }: any) => {
    return Promise.resolve();
    // return Promise.reject();
  },
  deleteOne: async ({ resource, id, variables, metaData }: any) => {
    try {
      await deleteDoc(doc(db, resource, id));
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  },
  deleteMany: ({ resource, ids, variables, metaData }: any) => {
    return Promise.resolve();
    // return Promise.reject();
  },
  getList: async ({
    resource,
    pagination,
    hasPagination,
    sort,
    filters,
    metaData,
  }: any) => {
    try {
      // In most cases, the data we want to fetch
      // will be data relevant to the store currently logged in so we check a cookie,
      // but on registration, we want to retrieve category data so we don't check in that case.
      if (resource === "categories") {
        const q = query(collection(db, resource));
        const snapshot = await getDocs(q);
        const data = snapshot.docs.map((c) => {
          const cat = c.data();
          return { ...cat, id: cat.title };
        });
        data.sort((a: any, b: any) => {
          if (a.title < b.title) {
            return -1;
          } else {
            return 1;
          }
        });
        return Promise.resolve({ data });
      }

      const USERS_DETAILS = "user details";
      let user = localStorage.getItem(USERS_DETAILS);
      let users: any = user ? JSON.parse(user) : {};
      const queryConstraints = [];
      if (filters) {
        for (let item of filters) {
          if (item.operator == "boolean") {
            queryConstraints.push(
              where(item.field, "==", item.value == "true" ? true : false)
            );
          } else if (item.operator == "id" || item.operator == "in") {
          } else {
            if (item.value && Array.isArray(item.value)) {
              for (let val of item.value) {
                queryConstraints.push(where(item.field, "==", val));
              }
            } else if (resource !== "orders" && item.value) {
              queryConstraints.push(where(item.field, "==", item.value));
            }
          }
        }
      }
      if (sort) {
        for (let item of sort) {
          queryConstraints.push(orderBy(item.field, item.order));
        }
      }
      // else {
      //     queryConstraints.push(orderBy('updateAt', 'desc'))
      // }

      // Some orders may use the store's UID field
      const uidConstraints = where("store", "==", users.uid);
      const queryConstraints1 = [...queryConstraints, uidConstraints];

      // const queryConstraints1 = resource === "users"
      //   ? queryConstraints
      //   : [...queryConstraints, uidConstraints];

      let q = query(collection(db, resource), ...queryConstraints1);
      const querySnapshot = await getDocs(q);
      let collections: any[] = [];
      querySnapshot.forEach((doc: any) => {
        collections.push({ ...doc.data(), id: doc.id });
      });

      // Some orders may use the store's document ID field
      const idConstraints = where("store", "==", users.id);
      const queryConstraints2 = [...queryConstraints, idConstraints];
      const q2 = query(collection(db, resource), ...queryConstraints2);
      const snapshot2 = await getDocs(q2);
      snapshot2.forEach((doc: any) => {
        collections.push({ ...doc.data(), id: doc.id });
      });

      collections.sort(
        (a: any, b: any) =>
          new Date(b.updateAt).getTime() - new Date(a.updateAt).getTime()
      );
      if (resource == "orders") {
        let filterId = filters.find((item: any) => item.operator == "id");
        if (filterId && filterId.value) {
          let collection = collections.filter(
            (item: any) => item.id == filterId.value
          );
          collections = collection ? collection : [];
        }
        let filterIn = filters.find((item: any) => item.operator == "in");
        if (filterIn && filterIn.value) {
          let collection = collections.filter((element: any) =>
            filterIn.value.includes(element.orderStatus)
          );
          collections = collection ? collection : [];
        }
        // Try sorting order array by date, until this if-statement ends.
        collections = collections.map((order: any) => {
          const date = getDate(order);
          if (date) {
            return { ...order, date };
          } else {
            return order;
          }
        });
        collections = collections.sort((a: any, b: any) => {
          if (a.date && b.date) {
            return b.date.getTime() - a.date.getTime();
          } else if (a.date) {
            return 1;
          } else if (b.date) {
            return -1;
          } else {
            return 0;
          }
        });
        const ordersWithDate = collections.filter((order: any) => order.date);
        const ordersWithoutDate = collections.filter(
          (order: any) => !order.date
        );
        collections = [...ordersWithDate, ...ordersWithoutDate];
      }
      // A Map doesn't have duplicate values (like order number)
      // and we previously encountered a bug where order numbers were duplicated
      // so put all array elements in a Map, then put all Map elments in an array
      // to remove duplicates and return the array.
      const map = new Map();
      collections.forEach((o) => map.set(o.orderNumber, o));
      collections = [];
      map.forEach((o) => collections.push(o));

      return Promise.resolve({ data: collections });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getMany: ({ resource, ids, metaData }: any) => {
    return Promise.resolve();
    // return Promise.reject();
  },
  getOne: async ({ resource, id, metaData }: any) => {
    try {
      const docRef = doc(db, resource, id);
      const docSnap = await getDoc(docRef);
      return Promise.resolve({ data: { ...docSnap.data(), id } });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  update: ({ resource, id, variables, metaData }: any) => {
    variables["updateAt"] = String(new Date());
    if (resource == "orders") {
      variables.employeeID = variables.employeeID ? variables.employeeID : "";
    }
    const docRef = doc(db, resource, id);
    updateDoc(docRef, variables)
      .then((docRef) => {
        if (resource == "stores") {
          window.open("/orders", "_self");
        }
        return Promise.resolve();
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  },
  updateMany: ({ resource, ids, variables, metaData }: any) => {
    return Promise.resolve();
    // return Promise.reject();
  },
  custom: ({
    url,
    method,
    sort,
    filters,
    payload,
    query,
    headers,
    metaData,
  }: any) => Promise,
  getApiUrl: () => "",
};

export const errorProvider = {
  logError: async (src: string, err: any) => {
    const errMsg = JSON.stringify(err);
    const obj = { src, errMsg };
    const dbRef = collection(db, "store-dashboard-errors");
    await addDoc(dbRef, obj);
    return;
  },
};
